import React, { Component } from 'react'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import { Flex, Box } from '@rebass/grid'
import Row from '../components/page-content-row'
import Title from '../components/page-title'
import PageOverview from '../components/page-overview'
import PageGalleryGrid from '../components/page-gallery-grid'

import Image1 from '../images/gallery/happyhaus-gallery-photo-dewar-AM-2-2.jpg'
import Image2 from '../images/gallery/happy-haus-mahogany-19.jpg'
import Image3 from '../images/gallery/happyhaus-gallery-2019-05-30-photo-display-banyo-AM-010.jpg'
import Image4 from '../images/gallery/happyhaus-gallery-2019-11-07-photo-sherwood-haus-2-am-0.jpg'
import { theme } from '../util/style'
const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

export default class Gallery extends Component {
  _getGalleryThumbs() {}

  render() {
    const { data } = this.props
    const galleryData = data.allGalleryJson.edges

    return (
      <>
        <SEO
          title="Gallery"
          description="You and your family can have a home as individual as the lives
                you lead and the people you are. Be inspired by a selection of
                our completed architecturally-designed homes."
          images={ogImages}
        />

        <Row>
          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 9 / 12]} px={2}>
              <Title
                align="left"
                mt={['35px', '85px']}
                style={{ fontFamily: theme.font.fontFamilyBold }}
              >
                Select Projects{' '}
                <span style={{ fontFamily: theme.font.fontFamilySerifThin }}>
                  2009—{new Date().getFullYear()}
                </span>
              </Title>
            </Box>
          </Flex>
        </Row>

        <Row>
          <PageGalleryGrid range={galleryData} />
        </Row>
      </>
    )
  }
}

export const query = graphql`
  query {
    allGalleryJson(sort: { fields: [sequence], order: DESC }) {
      edges {
        node {
          id
          completion
          title
          storey
          thumb
          summary
          gallery {
            image
          }
        }
      }
    }
  }
`
