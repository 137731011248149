import React, { Component } from 'react'
import { Link } from 'gatsby'
import slug from 'slug'
import { imgUrl } from '../util/helpers'
import styled from 'styled-components'
import { theme } from '../util/style'
import Image400 from '../components/page-image-400'
import { Box } from '@rebass/grid'

const renderTitle = (title) => {
  return title.split(/(\d+)/).map((part, index) =>
    /\d/.test(part) ? (
      <span key={index} className="number">
        {part}
      </span>
    ) : (
      part
    )
  )
}

export default class GalleryThumb extends Component {
  render() {
    const { data } = this.props
    const url = `/gallery-${slug(data.id)}/`
    return (
      <Item>
        <Link to={url}>
          <Image400 src={data.thumb} ratio={9 / 16} />
          <span className="thumbnail-backdrop">
            <span className="thumbnail-title">{renderTitle(data.title)}</span>
          </span>
        </Link>
      </Item>
    )
  }
}

const Item = styled.div`
  margin-bottom: 20px;
  font-size: 95%;
  img {
    display: block;
    max-width: 100%;
  }

  span.thumbnail-title {
    font-family: ${theme.font.fontFamilyBold};
  }

  span.number {
    font-family: ${theme.font.fontFamilyThin};
  }
  a {
    display: block;
    color: ${theme.colors.grey1};
    position: relative;
    span.thumbnail-backdrop {
      background: rgba(49, 51, 58, 0.7);
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      padding: 20px;
      color: ${theme.colors.white};
    }
    &:hover {
      span.thumbnail-backdrop {
        opacity: 1;
      }
      img {
        opacity: 0.9;
      }
    }
  }
`
