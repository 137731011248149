import React, { Component } from 'react'
import { Flex, Box } from '@rebass/grid'
//import { range } from '../util/data'
import styled from 'styled-components'
import _ from 'lodash'
import GalleryThumb from './gallery-thumb'

class PageGalleryGrid extends Component {
  _getRangeItems() {
    const filteredRange = (range, storey) => {
      return _.filter(range, (o) => {
        return o.node.storey === storey
      })
    }
    const storeyRange = this.props.storey
      ? filteredRange(this.props.range, this.props.storey)
      : this.props.range

    return storeyRange.map(function (item, i) {
      const key = `range-${i}`
      return (
        <Box key={key} width={[1, 1 / 3]} px={'10px'}>
          <GalleryThumb data={item.node} />
        </Box>
      )
    })
  }

  render() {
    const rangeItems = this._getRangeItems({ storey: this.props.storey })
    return (
      <Wrap>
        <Flex flexWrap="wrap">{rangeItems}</Flex>
      </Wrap>
    )
  }
}

export default PageGalleryGrid

const Wrap = styled.div``
